import Tab from './Tab.js';
import SignUpForm from './SignUpForm.js';
import AboutUvuli from './AboutUvuli.js';
import AboutTheFounders from './AboutTheFounders.js';
import { useState } from 'react';
import logo from './uheart-logo.png';

function TabContainer({children}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-t border-gray-200 dark:text-gray-400 dark:border-gray-700 flex justify-between">
        <img className="max-w-16 p-1.5" src={logo}  alt="logo" />
        <span className="-mb-px">
          <Tab tabName="Get Involved"
            isActive={activeTab === 0}
            tabSelected={() => setActiveTab(0)} />
          { /** <Tab tabName="About"
            isActive={activeTab === 1}
            tabSelected={() => setActiveTab(1)} /> */ }
        </span>
      </div>
      <div class="overflow-visible border-t border-l border-r border-gray-400 px-3 py-10 bg-gradient-to-br from-primary to-secondary flex justify-center">
        {activeTab === 0 && <SignUpForm />}
        {activeTab === 1 && <AboutUvuli />}
      </div>
    </div>

  );
}

export default TabContainer;