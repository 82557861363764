import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import InterviewSignUp from './InterviewSignUp';

function App() {
	return (
		<Router>
            <Routes>
                <Route exact path='/' exact element={<Home />} />
                <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                <Route path='/Terms' element={<TermsAndConditions />} />
                <Route path='/InterviewSignUp' element={<InterviewSignUp />} />
            </Routes>
        </Router>
	);
}

export default App;
