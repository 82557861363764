import { useState, useEffect } from 'react';
import isValidEmail from './EmailValidator.js';
import { Turnstile } from '@marsidev/react-turnstile';

function EmailRegistration({isTurnstileValid, setTurnstileValid}) {
	const [email, setEmail] = useState('');
	const [showSubmitForm, setshowSubmitForm] = useState(true);
	const [showTurnstile, setShowTurnstile] = useState(true);
	const [buttonText, setButtonText] = useState("Join Email List");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [turnstileToken, setTurnstileToken] = useState('');
	const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(true);
	const [responseText, setResponseText] = useState(null);
	const [emailAdded, setEmailAdded] = useState(false);
	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [email]);

	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [termsAndConditionsChecked]);

	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [turnstileToken]);

	const addEmailToList = () => {
		let formData = new FormData();
		formData.append('token', turnstileToken);
		console.log(email);
		(async () => {
			const response = await fetch("/AddEmailToList?emailToAdd=" + email, {
			method: "POST",
			body: formData
			});
			console.log(response);
			setEmailAdded(true);
			setResponseText('Thank you for joining our email list!');
		})();
	};

	function emailSubmitted() {
		setResponseText('Submitting...');
		setshowSubmitForm(false);
		addEmailToList();
	}

	function turnstileCallback(token) {
		setTurnstileToken(token);
	}

	function emailInputChanged(e) {
		setEmail(e.target.value);
	}

	function termsAndConditionsToggled() {
		setTermsAndConditionsChecked(!termsAndConditionsChecked);
	}

	function shouldSubmitButtonBeEnabled() {
		if(email && isValidEmail(email) && termsAndConditionsChecked && turnstileToken !== '') {
			return true;
		} else {
			return false;
		}
	}

	return (
		<div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary flex flex-col justify-between">
			<div className="text-center pb-7 text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
				Email
			</div>
			<div>
				<div className="pb-2 text-center" style={{ display: showSubmitForm ? 'block' : 'none'}} >
					Along with following our social media, we’re happy to provide you updates by email. Enter your email to stay updated with what we have to share in innovating the dating world!
				</div>
				<div styleName={{ display: showSubmitForm ? 'none' : 'block' }} className="text-center">
					{responseText}
				</div>
				<input
				className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				value={email}
				onChange={emailInputChanged}
				id="email"
				type="text"
				placeholder="Email"
				style={{ display: showSubmitForm ? 'block' : 'none'}} />
				{/** <div className="left-section pt-2">
					<input
						type="checkbox"
						id='terms-and-conditions'
						name={termsAndConditionsChecked}
						onChange={termsAndConditionsToggled}
						required />
					<label className="required-field ml-2">Agree to our Terms and Conditions you blowhard</label>
				</div>*/}
			</div>
			<div className="pt-7 text-center" style={{ display: showSubmitForm	? 'block' : 'none' }}>
				<a
					className="btn btn-primary"
					onClick={emailSubmitted}
					disabled={isButtonDisabled} >
					
					{buttonText}
				</a>
				<Turnstile className="pt-3" siteKey="0x4AAAAAAAjstFUDnC14PVv6" onSuccess={turnstileCallback} />
			</div>
			<div className="pt-2 text-center" style={{ display: showSubmitForm	? 'none' : 'block' }}>
			</div>
		</div>
	);
}

export default EmailRegistration;