import { useState, useEffect } from 'react';
import './output.css';
import AppointmentScheduler from './AppointmentScheduler';

function InterviewSignUp() {

  return (
    <div class="px-3 pb-10">
      <div class="flex justify-center">
          <a
            className="btn btn-secondary"
            href="/">
            Back to home
          </a>
        </div>
      <AppointmentScheduler /> 
    </div>
  );
}

export default InterviewSignUp;